import { IUserGlobal } from "../../../models/IUser";

export interface UserState {
    user: IUserGlobal | null,
}

export enum UserActionEnum {
    SET_USER = "SET_USER",
    FETCH_USER = "FETCH_USER",
}

export interface SetUser {
    type: UserActionEnum.SET_USER;
    payload: IUserGlobal | null;
}

export interface FetchUser {
    type: UserActionEnum.FETCH_USER;
    payload: number
}

export type UserAction =
    SetUser |
    FetchUser