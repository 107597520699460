import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { Col, Form } from "react-bootstrap";
import { IFieldFront, TypesOfInput } from "../../models/IField";
import CheckboxInput from "./inputs/Checkbox";
import CustomInput from "./inputs/Custom";
import DatePickerField from "./inputs/DatePicker";
import FileDropInput from "./inputs/FileDrop";
import SelectInput from "./inputs/Select";
import SelectRegions from "./inputs/SelectRegions";
import Password from './inputs/Password';
import Phone from './inputs/Phone';

interface FieldWithValidationProps {
  field: IFieldFront,
}

const FieldWithValidation: FC<FieldWithValidationProps> = ({ field }: FieldWithValidationProps) => {
  const renderInput = () => {
    switch (field.type) {
      case TypesOfInput.SELECT_REGIONS:
        return SelectRegions;

      case TypesOfInput.PASSWORD:
        return Password;

      case TypesOfInput.PHONE:
        return Phone;

      case TypesOfInput.FILE:
        return FileDropInput;

      case TypesOfInput.DATEPICKER:
        return DatePickerField;

      case TypesOfInput.SELECT:
        return SelectInput;

      case TypesOfInput.CHECKBOX:
        return CheckboxInput;

      default:
        return CustomInput;
    }
  };

  return (
    <Col lg={field.size || '4'}>
      <Form.Group className={`
          form-group 
          ${field.type === TypesOfInput.CHECKBOX ? 'field-with-validation__checkbox' : ''}
        `}>
        <div className="field-with-validation__block">
          {field.title && <Form.Label htmlFor={field.id}>{field.title}</Form.Label>}
          <Field name={field.id} id={field.id} input={field} component={renderInput()} />
        </div>
        <p className="error">
          <ErrorMessage name={field.id} />
        </p>
      </Form.Group>
    </Col>
  )
}

const memoFieldWithValidation = React.memo(FieldWithValidation);

export default memoFieldWithValidation;