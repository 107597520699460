export const Regulars = {
    password: {
        reg: /^(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z!@#:.\-"`/|\\(){}[\]=+,<>~;'_$%^&*?]{8,}$/,
        text: 'At least 8 characters, at least one upercase, at least one digit',
    },
    email: {
        reg: /^(([^<>()[\]\\.,#%^&`?/()~<>={}|*$!;:\s@"]+(\.[^<> ()[\]\\.,#%^&`?/()~<>={}|*$!;:\s@"]+)*)|(".+"))@((\[[0-9 ]{1,3}\.[0-9 ]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        text: 'Email is invalid',
    }
};

export const messages = {
    SHOW_MODAL_TITLE_EMAIL: "We sent a code to your email address.",
    SHOW_MODAL_DES_EMAIL: "The code we sent you to your email is for you to confirm your email",
    SHOW_MODAL_TITLE_2FA: "The code to enter the cabinet was sent to your email",
    SUCCESS_LOGIN: "You have successfully logged in to the cabinet",
    SUCCESS_REGISTER: "You have successfully registered in the cabinet",
    SUCCESS_PASSWORD_CHANGE: "You have successfully changed your password",
    SUCCESS_PASSWORD_RESET_TITLE: "Password Reset",
    SUCCESS_PASSWORD_RESET: "If this email address was used to create an account, instructions to reset your password will be sent to you. Please check your email.",
    LOGIN_VALIDATION_ERROR: "The account login was incorrect or wrong password has been typed. Please try again.",
    GOOGLE_2FA: "Google authenticator is still in development",
    CHANGE_2FA_TITLE: "We sent you the code to connect two-factor authorization by email",
    CHANGE_2FA_DES: "When you enter the code, you will be able to enter the cabinet only with the code that we will send you to the email that you entered during registration",
    REMOVE_SESSIONS: "You only have one active session",
    REMOVE_SESSIONS_SUCCESS: "All active sessions have been crossed except the current one",
}

// /^(([^<>()[\\]\\\\.,;:\\s@\\\"]+(\\.[^<> ()[\\]\\\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@((\\[[0-9 ]{1,3}\\.[0-9 ]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/



// export const FirstComponent = () => {  
//     const [count, setCount] = useState(false);  
//     const handleClick = useCallback(  
//       () => {  
//       showData(count);  
//     },  
//     [count],  
//   );  
//     return (  
//       [...]  
//       <SecondComponent onClick={() => {handleClick}/>  
//       [...]  
//     );  
//   }  


// export const exampleComponentPropsAreEqual(prev, next) {
//     return prev.count === next.count;
//   }
  
//   const MemoizedExampleComponent = React.memo(ExampleComponent, exampleComponentPropsAreEqual);

// const exampleList = useMemo(  
//     () => items.map(  
//       item => ({  
//         ...item,  
//         date: convertToLocalDateFunc(item.date)
//       }),  
//     ),  
//     [items],  
//   );  