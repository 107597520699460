
//router
import { Switch, Route, Redirect } from 'react-router-dom'
//layoutpages
import Dashboard from '../layouts/Dashboard'

import Auth, { AuthRouteNames, authRoutes } from './Auth'
import Errors, { errorRoutes } from './Errors'
import { IRoute } from '../models/IRoute'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { DashboardRouteNames, dashboardRoutes } from './Dashboard'
import Loader from '../components/Loader'

export enum MainRouteNames {
  DASHBOARD = '/dashboard',

  AUTH = '/auth',
  ERRORS = '/errors',
}

export const publicRoutes: IRoute[] = [
  { path: MainRouteNames.AUTH, component: Auth },
  { path: MainRouteNames.ERRORS, component: Errors },
  ...authRoutes,
  ...errorRoutes,
]

export const privateRoutes: IRoute[] = [
  { path: MainRouteNames.DASHBOARD, component: Dashboard },
  { path: MainRouteNames.ERRORS, component: Errors },
  ...errorRoutes,
  ...dashboardRoutes,
]

const IndexRouters = () => {
  const { isAuth } = useTypedSelector(state => state.auth);
  return (
    <>
      <Loader />
      {
        isAuth ?
          <Switch>
            {privateRoutes.map(route =>
              <Route path={route.path}
                exact={route.exact}
                component={route.component}
                key={route.path}
              />
            )}
            <Redirect to={DashboardRouteNames.USER_PROFILE} />
          </Switch>
          :
          <Switch>
            {publicRoutes.map(route =>
              <Route path={route.path}
                exact={route.exact}
                component={route.component}
                key={route.path}
              />
            )}
            <Redirect to={AuthRouteNames.SIGN_IN} />
          </Switch>
      }
    </>
  );
}

export default IndexRouters
