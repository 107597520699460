import axios from "axios"
import makeFormData from "../hooks/useMakeFormData";
import { ICategoryField, IFieldBack, IFieldFront, IFieldOption, IFilledField, TypesOfDatePickers, TypesOfInput } from "../models/IField";
import { IRoles } from "../models/IRoles"
import { notificationError } from "../plugins/requestNotification";
import * as Yup from 'yup';
import { ISuccessResponse, IErrorResponse } from "../models/IResponse";
import { groupBy } from "../plugins/helpers";

export const createRulesForField = (listOfFields: string[], sortedFields: IFieldFront[]) => {
  const validations: any = {};

  for (let fieldOfList of listOfFields) {
    const currentField: IFieldFront | undefined = sortedFields.find((field: IFieldFront) => field.id === fieldOfList)

    if (!currentField) {
      continue;
    }

    // add ONLY required validation
    if (!currentField?.validation) {
      if (currentField.required) {
        validations[fieldOfList] = Yup
          .string()
          .required('This field is required')
      }
    } else {
      // validation for DATEPICKER
      if (currentField.type === TypesOfInput.DATEPICKER) {
        // LOGIC and required validation for DATEPICKER fields
        if (currentField.required) {
          switch (currentField.validation) {
            // validation EXPIRATION_DATE for documents
            case TypesOfDatePickers.EXPIRATION_DATE: {
              const today = new Date()

              validations[fieldOfList] = Yup
                .date()
                .required('This field is required')
                .nullable()
                .min(today, currentField.validation_message)
              break;
            }

            // validation EXPIRATION_DATE for birthdate
            case TypesOfDatePickers.ADULTHOOD: {
              const adulthood = new Date();
              adulthood.setFullYear(adulthood.getFullYear() - 18);

              validations[fieldOfList] = Yup
                .date()
                .required('This field is required')
                .nullable()
                .max(adulthood, currentField.validation_message)
              break;
            }

            default:
              break;
          }
        } else {
          // ONLY required validation for DATEPICKER fields
          switch (currentField.validation) {
            // validation EXPIRATION_DATE for documents
            case TypesOfDatePickers.EXPIRATION_DATE: {
              validations[fieldOfList] = Yup
                .date()
                .nullable()
                .min(new Date(), currentField.validation_message)
              break;
            }

            // validation EXPIRATION_DATE for birthdate
            case TypesOfDatePickers.ADULTHOOD: {
              validations[fieldOfList] = Yup
                .date()
                .nullable()
                .min(new Date(), currentField.validation_message)
              break;
            }

            default:
              break;
          }
        }
      } else {
        // validation for text fields
        const regexpWithoutEnding = currentField?.validation.substring(1, currentField?.validation.length - 1);
        const regular = regexpWithoutEnding.replace(/\\\\/g, '\\')
        const regexp = new RegExp(regular || '');

        // regExp and required validation for text fields
        if (currentField.required) {
          validations[fieldOfList] = Yup
            .string()
            .required('This field is required')
            .matches(regexp, currentField?.validation_message)
        } else {
          // ONLY regExp validation for text fields
          validations[fieldOfList] = Yup
            .string()
            .matches(regexp, currentField?.validation_message)
        }
      }
    }
  }

  return validations;
}

export const GetFields = async (step: number): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/role/${IRoles.GUEST}/step/${step}`;

    interface IData {
      id: string
      name: string
      roleFields: {
        fields: {
          [key: string]: IFieldBack
        },
        total_step: number,
      }
      time: string
    }
    const response: ISuccessResponse<IData> = await axios.get(url);

    const listOfFields: string[] = Object.keys(response.data.data.roleFields.fields)
    const fetchedFileds: [string, IFieldBack][] = Object.entries(response.data.data.roleFields.fields);
    let sortedFields: IFieldFront[] = fetchedFileds
      .map((field: [string, IFieldBack]) => {
        return {
          ...field[1],
          required: Boolean(field[1].required),
          id: field[0]
        }
      })
      .sort((a, b) => Number(a.order) - Number(b.order));

    const initialValues: IFilledField = {};

    listOfFields.forEach((field: string) => initialValues[field] = '');

    const validations = createRulesForField(listOfFields, sortedFields)

    sortedFields = sortedFields
      .filter((item: IFieldFront) => item.id !== 'phone_code');

    if (step === 2) {
      initialValues['phone_code'] = '';
    }

    const categoriesNames: { [key: number]: string } = {
      1: "Personal Info",
      2: "Identification Type",
    }

    const groupedFieldsById = groupBy(sortedFields, i => i.category_id);
    const categoriesIds = Object.keys(groupedFieldsById);

    const sortedFieldsByCategory: ICategoryField[] = categoriesIds.map((key: string) => {
      const idOfCategry = Number(key);

      return {
        id: idOfCategry,
        name: categoriesNames[idOfCategry],
        fields: groupedFieldsById[idOfCategry]
      }
    })

    return {
      sortedFields,
      initialValues,
      validations,
      totalStep: response.data.data.roleFields.total_step,
      sortedFieldsByCategory,
    };
  } catch (e: any) {
    notificationError(e)
    return e?.response;
  }
}

export const PostImage = async (name: string, photo: File): Promise<ISuccessResponse<any> | IErrorResponse> => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/user-photo`;

    const form = {
      [name]: photo
    }

    const formData: any = makeFormData(form);

    const { data }: ISuccessResponse<any> = await axios.post(url, formData);

    return data.data;
  } catch (e: any) {
    notificationError(e)
    return e?.response;
  }
}

export const GetRegionsOptions = async (country: string): Promise<IFieldOption[] | undefined> => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/get-geo-regions/${country}`;

    const { data }: ISuccessResponse<IFieldOption[]> = await axios.get(url);

    return data.data;
  } catch (e: any) {
    notificationError(e)
  }
}