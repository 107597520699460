import { useFormikContext, FieldInputProps } from "formik";
import { FC, useEffect, useState } from "react";
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en';
interface DatePickerProps {
  field: FieldInputProps<any>;
}

const DatePicker: FC<DatePickerProps> = ({ field }) => {
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext() ?? {};
  const [datepicker, setDatepicker] = useState<AirDatepicker | null>(null);

  useEffect(() => {
    const datepickerLocal: AirDatepicker = new AirDatepicker(`#${field.name}`, {
      locale: localeEn,
      selectedDates: [new Date(field.value)],
      onSelect: ({ date, formattedDate, dp }: { date: Date | Date[]; formattedDate: string | string[]; dp: AirDatepicker; }) => {
        setFieldValue(field.name, date || '');
        setFieldTouched(field.name, true, false)
      },
    })
    datepickerLocal.setViewDate(new Date(field.value))
    setDatepicker(datepickerLocal)
  }, [])

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 9) {
      setFieldError(field.name, 'Invalid date');
      setFieldTouched(field.name, true, false);
      return;
    };
    const date: Date | string = new Date(e.target.value);

    if (!date && !datepicker) return;

    if (date instanceof Date && date.getTime()) {
      datepicker?.setViewDate(date)
      datepicker?.selectDate(date)
      setFieldValue(field.name, date);
      setFieldTouched(field.name, true, false)
    } else {
      setFieldError(field.name, 'Invalid date')
      setFieldTouched(field.name, true, false)
    }
  }

  return (
    <div className="react-datepicker__input-container">
      <input onChange={handleChangeDate} id={field.name} />
    </div>
  );
};

export default DatePicker;