import auth from './auth';
import app from './app';
import user from './user';
import profile from './profile';
import Mode from './setting/setting';

const reducers = {
    auth,
    mode: Mode,
    app,
    user,
    profile,
}

export default reducers;