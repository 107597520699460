import { FC } from "react";
import { FieldInputProps } from "formik";
import { IFieldFront } from "../../../models/IField";

interface CustomProps {
  field: FieldInputProps<any>;
  input: IFieldFront,
}

const Custom: FC<CustomProps> = ({
  field, // { name, value, onChange, onBlur }
  input,
}) => {
  return (
    <div className="input">
      <input
        {...field}
        disabled={input.disabled}
        type={input.type}
        className="input__field"
      />
    </div>
  )
}


export default Custom;