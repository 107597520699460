import React, { useEffect, useState, useRef } from 'react'

import { Row, Col, Tab, Button, Form as FormBootstrap, FormCheck } from 'react-bootstrap'
import Card from '../../../components/Card'
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

// img
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { TypesOfInput } from '../../../models/IField';
import FieldWithValidation from '../../../components/uikit/FieldWithValidation';
import { handleDisableButton } from '../../../plugins/helpers';
import { IUserChangePassword } from '../../../models/IUser';
import { Get2AuthMethods, GetUserSessions, TerminateUserSession } from '../../../apis/profile';
import { IMethodOfAuth, ISession } from '../../../models/IProfile';
import { notificationError } from '../../../plugins/requestNotification';
import { messages, Regulars } from '../../../constants';
import { notification } from 'antd';

const UserProfile = () => {
  const { user } = useTypedSelector(state => state.user)
  const { modalValue, errors, isShowModal } = useTypedSelector(state => state.app)
  const { token } = useTypedSelector(state => state.profile)
  const { handleLoader, requestToChange2Auth, change2Auth, changePassword, logout } = useActions();
  const formButtonRef = useRef(null);
  const formRadioButton = useRef(null);
  const [authMethods, setAuthMethods] = useState([]);
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [formikActions, setFormicActions] = useState<any>(null);

  useEffect(() => {
    if (sessions.length) return;
    handleLoader(true);
    fetchDatas()
  }, [user])

  useEffect(() => {
    if (formikActions && errors && Object.values(errors).length) {
      formikActions.setErrors(errors)
    }
  }, [errors]);

  useEffect(() => {
    if (!modalValue || !token || isShowModal) return;
    change2Auth({
      code: modalValue,
      hash: token,
      status: !!user?.two_factor_auth ? 0 : 1
    })
  }, [isShowModal])

  const fetchDatas = async () => {
    if (user) {
      const { data } = await GetUserSessions(user?.user_id)
      setSessions(data.data)
    }
    const { data } = await Get2AuthMethods();
    handleLoader(false);
    setAuthMethods(data.data)
  }

  const schema = Yup.object().shape({
    old_password: Yup
      .string()
      .matches(Regulars.password.reg, Regulars.password.text)
      .required('Required'),

    password: Yup
      .string()
      .matches(Regulars.password.reg, Regulars.password.text)
      .required('Required'),

    confirm_password: Yup
      .string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });

  const handleSave = async (values: IUserChangePassword, form: any) => {
    setFormicActions(form);
    handleDisableButton(formButtonRef)
    changePassword({
      data: values,
      callback: () => form.resetForm({
        password: '',
        confirm_password: '',
        old_password: '',
      }),
    });
  }

  const handleChange2Auth = ({ target }: { target: HTMLInputElement }) => {
    handleDisableButton(formRadioButton)

    if (!user) return;

    requestToChange2Auth({
      method: Number(target.name),
      status: !!user?.two_factor_auth ? 0 : 1,
    })
  }

  const handleRemoveSession = async (scenario: 'one' | 'all', session?: ISession) => {
    if (!user) return;

    if (sessions.length === 1 && scenario === 'all') {
      notification.info({
        message: messages.REMOVE_SESSIONS,
      });
      return;
    }

    try {
      const { data } = await TerminateUserSession({
        user_id: user?.user_id,
        scenario,
        session_id: session?.id,
      })
      setSessions(data.data)
      if (scenario === 'all') {
        notification.success({
          message: messages.REMOVE_SESSIONS_SUCCESS,
        });
      }
      if (session?.current) {
        logout()
      }
    } catch (e) {
      notificationError(e)
    }
  }

  return (
    <>
      <Tab.Container defaultActiveKey="first">
        <Row>
          <Col lg="12">
            <Card>
              <Card.Header>
                <div className="header-title">
                  <h4 className="card-title">Change password</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    <Formik
                      initialValues={{
                        password: '',
                        confirm_password: '',
                        old_password: '',
                      }}
                      validationSchema={schema}
                      onSubmit={handleSave}
                    >
                      <Form>
                        <Row>
                          <FieldWithValidation field={{
                            id: 'old_password',
                            required: true,
                            type: TypesOfInput.PASSWORD,
                            title: 'Current Password',
                            size: 12,
                            category_id: 1,
                          }} />
                          <FieldWithValidation field={{
                            id: 'password',
                            required: true,
                            type: TypesOfInput.PASSWORD,
                            title: 'New Password',
                            size: 12,
                            category_id: 1,
                          }} />
                          <FieldWithValidation field={{
                            id: 'confirm_password',
                            required: true,
                            type: TypesOfInput.PASSWORD,
                            title: 'Confirm New Password',
                            size: 12,
                            category_id: 1,
                          }} />
                        </Row>
                        <div className="d-flex justify-content-center">
                          <Button
                            type="submit"
                            variant="primary"
                            ref={formButtonRef}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <div className="header-title">
                  <h4 className="card-title">Two-factor authentication</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center">
                    <Row>
                      {authMethods.map((method: IMethodOfAuth) =>
                        <FormBootstrap.Check key={method.id} className="form-check-inline">
                          <FormCheck.Input
                            onChange={handleChange2Auth}
                            className="me-1"
                            id={'checkbox-bottom-' + method.id}
                            name={`${method.id}`}
                            checked={(method.id === (user?.two_factor_auth_method || 0)) && Boolean(user?.two_factor_auth)}
                          />
                          <FormCheck.Label htmlFor={'checkbox-bottom-' + method.id} className="pl-2">
                            {method.name}
                          </FormCheck.Label>
                        </FormBootstrap.Check>
                      )}
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>
                <div className="header-title">
                  <h4 className="card-title">Active sessions: {sessions.length}</h4>
                </div>
                <Button
                  variant="primary"
                  onClick={() => handleRemoveSession('all')}
                >
                  Terminate all other sessions
                </Button>
              </Card.Header>
              <Card.Body>
                <div className="bd-example table-responsive">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>Country</th>
                        <th>Browser</th>
                        <th>Last connection</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sessions.map((session: ISession) =>
                        <tr key={session.id} className={`${session.current ? 'table-danger' : ''}`}>
                          <td>
                            <h6>{session.country}</h6>
                          </td>
                          <td>
                            <h6>{session.agent}</h6>
                          </td>
                          <td>
                            <h6>{session.created_at_human}</h6>
                          </td>
                          <td>
                            <Button
                              variant="outline-primary"
                              onClick={() => handleRemoveSession('one', session)}
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab.Container>
    </>
  )
}

export default UserProfile;